import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

// ASSETS
import arrowRight from '../../assets/arrow-right-line.svg';

import Aax from '../../assets/partnes/aax.png';
import Finanzero from '../../assets/partnes/finanzero.png';
import RudCred from '../../assets/partnes/rudcred.png';
import Rw from '../../assets/partnes/rw.png';

// COMPONENTS
import { Button, sideButton } from '@bancobarigui/reactbook';
import AdvantagesContainer from './components/AdvantagesContainer';
import Carousel from '../../components/UI/Carousel';
import CookieToastr from '../../components/CookieToastr';
import Disclaimer from '../../components/Disclaimer';
import HeaderBlack from '../../components/UI/HeaderBlack';
import HeroImage from './components/HeroImage';
import ModernWay from './components/ModernWay';
import PageTitle from './components/PageTitle';
import PartnerAdvantage from './components/PartnerAdvantage';
import PartnersForm from './components/PartnersForm';
import VideoSection from './components/VideoSection';
import withLayout from '../../components/UI/Layout';

// CONSTANTS
import { homeCarousel } from '../../constants/carousel-items';

// STYLES
import {
  Container,
  ContainerCarousel,
  Content,
  ContentCarousel,
  PartnersImg,
  SectionTitle,
  StickyButton,
} from './styles';

const scrollToRef = ref =>
  window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' });

const hasCookies = localStorage.getItem('@Bari_AllowCookies') ? true : false;

const Home = () => {
  const myRef = useRef(null);
  const [allowCookies, setAllowCookies] = useState(hasCookies);

  const executeScroll = () => scrollToRef(myRef);

  const handleAllowCookies = useCallback(() => {
    setAllowCookies(true);
    localStorage.setItem('@Bari_AllowCookies', true);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Seja um Parceiro Bari. Aumente sua renda com Crédito Imobiliário
        </title>
      </Helmet>
      <Container>
        <HeaderBlack onClick={executeScroll} />
        <PageTitle executeScroll={executeScroll} />
        <HeroImage />
        <Content>
          <ModernWay executeScroll={executeScroll} />
          <AdvantagesContainer />
          <VideoSection executeScroll={executeScroll} />
          <PartnerAdvantage executeScroll={executeScroll} />
          <StickyButton>
            <Button
              blue
              sideIcon={sideButton.RIGHT}
              icon={() => <img src={arrowRight} alt="Arrow right" />}
              onClick={executeScroll}
            >
              Seja nosso parceiro
            </Button>
          </StickyButton>
          <ContainerCarousel>
            <ContentCarousel>
              <SectionTitle>
                Confira os <strong>parceiros que nos ajudam</strong> a promover
                o crédito inteligente.
              </SectionTitle>
              <PartnersImg>
                <img src={Rw} alt="Logo Rw" />
                <img src={Aax} alt="Logo AAX" />
                <img src={RudCred} alt="Logo RudCred" />
                <img src={Finanzero} alt="Logo Finanzero" />
              </PartnersImg>
              <Carousel items={homeCarousel} />
            </ContentCarousel>
          </ContainerCarousel>
          <PartnersForm refProp={myRef} />
          <Disclaimer />
        </Content>
        {!allowCookies && (
          <CookieToastr
            onClick={handleAllowCookies}
            allowCookies={allowCookies}
          />
        )}
      </Container>
    </>
  );
};

export default withLayout({ header: false, footer: true })(Home);
